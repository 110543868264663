import React from 'react';
import { graphql } from "gatsby"
import Layout from "../page-components/layout"
import SEO from "../page-components/seo"
import RcCard from "../components/card/rcCard"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"

const GutachtenPage = ( { data } ) => {

  return <Layout>

    <SEO title={"Gutachten"} />

    <RcDefaultPageTeaser>
      <h1>Gutachten</h1>

      <div dangerouslySetInnerHTML={
        {
          __html: data.wp.texteFRSektionen.acf.introgutachten
        }
      } />

    </RcDefaultPageTeaser>

    <div className="container bg-white h-100 pt-3">

      <div className="row pb-5">
        { data.allWpPage.nodes.map(
          n =>  <RcCard to={"/" + n.slug}
                        caption={n.title}
                        className={"mb-4"}
                        innerClassName={"h-100"}
                        description={n.acf.description}
                        img={n.acf?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData} />
        )}
      </div>

    </div>

  </Layout>

};

export default GutachtenPage;

export const query = graphql`
  query GutachenPageQuery {
  
    wp {
      texteFRSektionen {
          acf {
            introgutachten
          }
      }
    }
  
    allWpPage(filter: {acf: {art: {in: [ "gutachten" ] }}}) {
      nodes {
        acf {
          art
          description
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        slug
        title
      }
    }
  }`
